<template>
  <v-container class="mx-0" fluid>
    <v-card elevation="1" class="pa-3 mb-10">
      <div class="text-start mb-2">
        <span class="font-weight-bold grey--text text--lighten-1 body-1">
          DOKUMEN PERSETUJUAN
        </span>
      </div>
      <div>
        <v-progress-circular
          class="pt-3"
          :value="loading.documents.value"
          size="100"
          color="primary"
          v-if="loading.documents.state"
        ></v-progress-circular>
        <v-card
          v-else
          v-for="item in documents.filter(
            doc =>
              doc.title.toLowerCase() !== 'surgical safety checklist' &&
              doc.title.toLowerCase() !==
                'penandaan operasi atau tindakan medis',
          )"
          :key="'document-' + item.index"
          elevation="0"
          class="document-card"
        >
          <v-row class="item-container">
            <v-col class="d-flex justify-start align-center text-start"
              ><h3>
                {{ item.title }}
              </h3></v-col
            >
            <v-col class="d-flex flex-column">
              <label class="grey--text body-2 text-start mb-2"
                >Tanggal Pembuatan Dokumen</label
              >
              <label class="font-weight-medium body-2 text-start">{{
                item.dateTime || '-'
              }}</label>
            </v-col>
            <v-col class="pa-0 text-start pt-2">
              <label class="grey--text body-2 text-start"
                >Yang Bertandatangan</label
              >
              <v-row class="pa-4 pl-3">
                <v-col cols="6" class="pa-0"
                  ><span class="subtitle-2">{{
                    item.patient || '-'
                  }}</span></v-col
                >
                <v-col cols="6" class="pa-0">
                  <label
                    v-if="item.patient"
                    class="subtitle-2 font-weight-light text-capitalize"
                    >{{ item.signer }}</label
                  ></v-col
                >
                <v-col cols="6" class="pa-0"
                  ><span class="subtitle-2">{{ item.staff || '' }}</span></v-col
                >
                <v-col cols="6" class="pa-0">
                  <label v-if="item.staff" class="subtitle-2 font-weight-light"
                    >Petugas</label
                  ></v-col
                >
              </v-row>
            </v-col>
            <v-col class="d-flex align-end justify-end">
              <v-progress-linear
                v-model="item.loading.value"
                v-if="item.loading.state"
                class="mr-3"
                color="primary"
                height="15"
                light
                dark
                rounded
              >
                <template v-slot:default="{ value }">
                  <h5>{{ Math.ceil(value) }}%</h5>
                </template>
              </v-progress-linear>
              <div class="file-container" v-if="item.document.length > 0">
                <div
                  style="width: 100%"
                  @click="previewFile(item.document[0].path)"
                >
                  {{ item.document[0].name }}
                </div>
                <v-icon style="z-index: 2" @click="deleteFile(item)" color="red"
                  >mdi-close</v-icon
                >
              </div>
              <div class="file-container error" v-if="item.documentError.file">
                <span class="red--text">{{
                  item.documentError.message + '.'
                }}</span>
                <span
                  style="cursor: pointer;"
                  class="text-decoration-underline red--text"
                  @click="addFile(item.index)"
                  >Coba lagi</span
                >
                <v-icon @click="removeWarning(item.index)" color="red"
                  >mdi-close</v-icon
                >
              </div>
              <input
                type="file"
                id="files"
                :ref="`files${item.index}`"
                accept="image/png, image/jpeg, application/pdf"
                multiple
                v-on:change="handleFilesUpload(item)"
                v-show="false"
              />
              <v-tooltip
                v-if="
                  item.document.length == 0 &&
                    !item.documentError.file &&
                    item.dateTime &&
                    !item.loading.state
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="addFile(item.index)"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="btn-icon mx-2"
                    slot="activator"
                  >
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </template>
                <span>Upload Dokumen</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="btn-icon"
                    @click="openForm(item)"
                    :class="{
                      'ml-3':
                        item.document.length > 0 || item.documentError.file,
                    }"
                    slot="activator"
                  >
                    <v-icon>mdi-file-document-edit</v-icon>
                  </v-btn></template
                >
                <span>Form {{ item.title }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-card>
    <v-card elevation="1" class="pa-3">
      <div class="text-start mb-2">
        <span class="font-weight-bold grey--text text--lighten-1 body-1">
          DOKUMEN PELAYANAN
        </span>
      </div>
      <div>
        <v-progress-circular
          class="pt-3"
          :value="loading.documents.value"
          size="100"
          color="primary"
          v-if="loading.documents.state"
        ></v-progress-circular>
        <v-alert
          v-if="
            documents.filter(
              doc =>
                doc.title.toLowerCase() === 'surgical safety checklist' ||
                doc.title.toLowerCase() ===
                  'penandaan operasi atau tindakan medis',
            ).length < 1 && !loading.documents.state
          "
          :value="true"
          type="info"
          class="white--text mx-3"
        >
          Maaf data dokumen pelayanan pasien tidak ditemukan
        </v-alert>
        <v-card
          v-else
          v-for="item in documents.filter(
            doc =>
              doc.title.toLowerCase() === 'surgical safety checklist' ||
              doc.title.toLowerCase() ===
                'penandaan operasi atau tindakan medis',
          )"
          :key="'document-' + item.index"
          elevation="0"
          class="document-card"
        >
          <v-row class="item-container">
            <v-col class="d-flex justify-start align-center text-start"
              ><h3>
                {{ item.title }}
              </h3></v-col
            >
            <v-col class="d-flex flex-column">
              <label class="grey--text body-2 text-start mb-2"
                >Tanggal Pelaksanaan</label
              >
              <label class="font-weight-medium body-2 text-start">{{
                item.dateAction
              }}</label>
            </v-col>
            <v-col
              v-if="item.title.toLowerCase() === 'surgical safety checklist'"
              class="pa-0 text-start pt-2"
            >
              <label class="grey--text body-2 text-start"
                >Tindakan yang Dilakukan</label
              >
              <v-row class="pa-4 pl-3">
                <v-col cols="6" class="pa-0"
                  ><span class="subtitle-2">{{ item.action }}</span></v-col
                >
              </v-row>
            </v-col>
            <v-col v-else class="pa-0 text-start pt-2">
              <label class="grey--text body-2 text-start"
                >Yang Bertandatangan</label
              >
              <v-row class="pa-4 pl-3">
                <v-col cols="6" class="pa-0"
                  ><span class="subtitle-2">{{
                    item.patient || '-'
                  }}</span></v-col
                >
                <v-col cols="6" class="pa-0">
                  <label
                    v-if="item.patient"
                    class="subtitle-2 font-weight-light text-capitalize"
                    >{{ item.signer }}</label
                  ></v-col
                >
                <v-col cols="6" class="pa-0"
                  ><span class="subtitle-2">{{ item.staff || '' }}</span></v-col
                >
                <v-col cols="6" class="pa-0">
                  <label v-if="item.staff" class="subtitle-2 font-weight-light"
                    >Petugas</label
                  ></v-col
                >
              </v-row>
            </v-col>
            <v-col class="d-flex align-end justify-end">
              <v-progress-linear
                v-model="item.loading.value"
                v-if="item.loading.state"
                class="mr-3"
                color="primary"
                height="15"
                light
                dark
                rounded
              >
                <template v-slot:default="{ value }">
                  <h5>{{ Math.ceil(value) }}%</h5>
                </template>
              </v-progress-linear>
              <div class="file-container" v-if="item.document.length > 0">
                <div
                  style="width: 100%"
                  @click="previewFile(item.document[0].path)"
                >
                  {{ item.document[0].name }}
                </div>
                <v-icon style="z-index: 2" @click="deleteFile(item)" color="red"
                  >mdi-close</v-icon
                >
              </div>
              <div class="file-container error" v-if="item.documentError.file">
                <span class="red--text">{{
                  item.documentError.message + '.'
                }}</span>
                <span
                  style="cursor: pointer;"
                  class="text-decoration-underline red--text"
                  @click="addFile(item.index)"
                  >Coba lagi</span
                >
                <v-icon @click="removeWarning(item.index)" color="red"
                  >mdi-close</v-icon
                >
              </div>
              <input
                type="file"
                id="files"
                :ref="`files${item.index}`"
                accept="image/png, image/jpeg, application/pdf"
                multiple
                v-on:change="handleFilesUpload(item)"
                v-show="false"
              />
              <v-tooltip
                v-if="
                  item.document.length == 0 &&
                    !item.documentError.file &&
                    item.dateAction &&
                    !item.loading.state
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="addFile(item.index)"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="btn-icon mx-2"
                    slot="activator"
                  >
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </template>
                <span>Upload Dokumen</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="btn-icon"
                    @click="openForm(item)"
                    :class="{
                      'ml-3':
                        item.document.length > 0 || item.documentError.file,
                    }"
                    slot="activator"
                  >
                    <v-icon>mdi-file-document-edit</v-icon>
                  </v-btn></template
                >
                <span>Form {{ item.title }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-card>
    <v-dialog v-model="dialog" :max-width="selectedForm === 3 ? 1120 : null">
      <general-consent-form
        v-if="dialog && id_emr && selectedForm === 0"
        :social-data="socialData"
        :id_emr="id_emr"
        @close-form="dialog = false"
      />
      <FormInformedConsent
        v-if="dialog && selectedForm === 1"
        state="detail"
        @close-form="dialog = false"
        persistent
      />
      <surgical-safety-checklist
        :closeForm="() => (dialog = false)"
        v-if="dialog && selectedForm === 2"
      />
      <form-operation-marking
        ref="formOperationMarking"
        v-if="dialog && selectedForm === 3"
        state="emr"
        @close-form="dialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import AlertMixin from '@/mixin/alertMixin';
import moment from 'moment-timezone';
import formatMixin from '../../../mixin/formatMixin';
import FormInformedConsent from '../../Registration/components/InformedConsent/FormInformedConsent.vue';
import GeneralConsentForm from '../../Registration/components/GeneralConsentForm.vue';
import SurgicalSafetyChecklist from '../../Service/components/ConsentDocuments/SurgicalSafetyChecklist.vue';
import FormOperationMarking from '../../Registration/components/OperationMarking/FormOperationMarking.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapMutations } = createNamespacedHelpers('InformedConsent');
const {
  mapActions: mapSscActions,
  mapMutations: mapSscMutations,
} = createNamespacedHelpers('SurgicalSafetyChecklist');
const { mapActions: opActions } = createNamespacedHelpers('OperationMarking');

const axios = require('axios');
export default {
  name: 'GeneralConsent',
  mixins: [AlertMixin, formatMixin],
  components: {
    GeneralConsentForm,
    FormInformedConsent,
    FormOperationMarking,
    SurgicalSafetyChecklist,
  },
  props: {
    id_emr: {
      type: String,
    },
    patientData: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      selectedForm: null,
      socialData: {}, // social data for general consent props
      informedConsentData: {}, // social data for informed consent props
      documents: [],
      loading: {
        documents: {
          state: false,
          value: 0,
        },
      },
    };
  },
  watch: {
    dialog() {
      this.showAllDocuments();
    },
  },
  mounted() {
    this.showAllDocuments();
    this.setSocialState();
  },
  computed: {
    isFinanceManager() {
      return this.$store.getters.userLoggedIn.role;
    },
  },
  methods: {
    ...mapMutations([
      'setIdIC',
      'setStateForm',
      'setIsChanged',
      'setShowDialog',
      'setDataPatient',
      'clearFormInformedConsent',
      'clearItemsInformedConsent',
    ]),
    ...mapSscMutations(['setIsEmr']),
    ...mapActions([
      'resolveGetInformConsentById',
      'resolveGetStaff',
      'resolveGetAllService',
    ]),
    ...mapSscActions(['resolveGetSurgicalSafetyChecklistById']),
    ...opActions(['resolveGetOperationMarkingById']),
    setSocialState() {
      this.socialData = {
        name: this.patientData.social.name,
        address: this.formatAddress(this.patientData.social.address),
        telp: this.patientData.social.phone_number,
        identityNumber: this.patientData.social.identity.number,
        age: this.patientData.social.age,
        gender: this.patientData.social.gender,
        rmNumber: this.patientData.medical_record_number,
        birthDate: moment(
          this.patientData.social.birth.date,
          'YYYY-MM-DD',
        ).format('DD/MM/YYYY'),
      };
    },
    openForm(data) {
      this.dialog = true;
      if (data.tag === 'generalconsent') {
        this.selectedForm = 0;
      }
      if (data.tag === 'surgicalsafetychecklist') {
        const data_patient = {
          rmNumber: this.patientData.medical_record_number,
          meta: {
            name: this.patientData.social.name,
            birthDate: moment(
              this.patientData.social.birth.date,
              'YYYY-MM-DD',
            ).format('DD MMMM YYYY'),
            gender: this.patientData.social.gender ? 'Laki-laki' : 'Perempuan',
            age: this.patientData.social.age,
          },
          id_registration: data.idRegistration,
          id_emr: this.id_emr,
        };
        this.$store.commit('changeSelectedPatient', data_patient);
        this.setIsEmr(true);
        this.resolveGetSurgicalSafetyChecklistById(data.id);
        this.selectedForm = 2;
      }
      if (data.tag === 'informconsent') {
        this.setDataPatient({
          noRM: this.patientData.medical_record_number,
          name: this.patientData.social.name,
          birthDate: moment(this.patientData.social.birth.date)
            .locale('id')
            .format('LL'),
          birthPlace: this.patientData.social.birth.place,
          address: this.patientData.social.address.text,
          age: this.patientData.social.age,
        });
        this.selectedForm = 1;
        this.setStateForm('detail');
        this.setIdIC(data.id);
        this.resolveGetStaff();
        this.resolveGetInformConsentById(data.id).then(response => {
          this.resolveGetAllService({
            keyword:
              response.detail?.information_checklist?.action?.value?.name,
            guarantorType: this.patientData.social.assurance.type.toLowerCase(),
          });
        });
        this.informedConsentData = {
          rmNumber: this.patientData.medical_record_number,
          id_registration: this.documents[data.index].idRegistration,
          address: this.formatAddress(this.patientData.social.address),
          id_emr: this.id_emr,
          meta: {
            name: this.patientData.social.name,
            phoneNumber: this.patientData.social.phone_number,
            birthDate: moment(
              this.patientData.social.birth.date,
              'YYYY-MM-DD',
            ).format('DD/MM/YYYY'),
          },
        };
      }
      if (data.tag === 'medicaltreatment') {
        this.setDataPatient({
          noRM: this.patientData.medical_record_number,
          name: this.patientData.social.name,
          birthDate: moment(this.patientData.social.birth.date)
            .locale('id')
            .format('LL'),
          birthPlace: this.patientData.social.birth.place,
          address: this.patientData.social.address.text,
          age: this.patientData.social.age,
          gender: this.patientData.social.gender ? 'Laki-laki' : 'Perempuan',
        });
        this.selectedForm = 3;
        this.resolveGetOperationMarkingById(data.id);
      }
    },
    async showAllDocuments() {
      this.loading.documents.state = true;
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(`/patient/document/${this.id_emr}`),
          {
            onDownloadProgress: progress => {
              const result = Math.floor(progress.loaded / progress.total) * 100;
              this.loading.documents.value =
                this.loading.documents.value + result;
              if (result == 100) {
                this.loading.documents.state = false;
              }
            },
          },
        );
        const { data } = response.data;
        this.documents = data.map((item, index) => {
          return {
            index,
            id: item._id,
            action: item.action,
            dateAction: item.date,
            title: item.title,
            tag: this.generateTag(item.title),
            dateTime: item.document_created,
            signer: item.signer,
            patient: item.signer_name,
            staff: item.signer_doctor || '-',
            companion: item.signer_staff || '-',
            idRegistration: item.id_registration || '',
            document: item.files
              ? [
                  {
                    name: item.files.files.original_file_name,
                    id: item.files.files._id,
                    path: item.files.files.path,
                  },
                ]
              : [],
            documentError: {
              file: '',
              message: '',
            },
            loading: {
              state: false,
              value: 0,
            },
          };
        });
      } catch {
        this.documents = [];
      }
    },
    addFile(index) {
      this.$refs[`files${index}`][0].click();
    },
    async previewFile(path) {
      window.open(Constant.apiUrl.concat(`/${path}`));
    },
    handleFilesUpload(data) {
      const filesUploaded = this.$refs[`files${data.index}`][0].files;
      if (filesUploaded.length > 1) {
        this.documents[data.index].documentError = {
          file: filesUploaded,
          message: 'Max hanya 1 file',
        };
        return;
      }
      if (this.verify(filesUploaded[0]) === '') {
        this.documents[data.index].documentError = {
          file: '',
          message: '',
        };
        this.uploadFile(filesUploaded[0], data);
      } else {
        this.documents[data.index].documentError = {
          file: filesUploaded[0],
          message: this.verify(filesUploaded[0]),
        };
      }
      this.$refs[`files${data.index}`][0].files.value = '';
    },
    verify(file) {
      const maxSize = 2000000;
      const allowedTypes = ['application/pdf'];
      const ext = file.name.split('.');
      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`;
      }
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 2MB';
      }

      return '';
    },
    postFile(isUpload, index) {
      if (index) return;
      axios
        .post(
          Constant.apiUrl.concat(
            `/document/${index ? 'inform' : 'general'}-consent/upload`,
          ),
          {
            isUploaded: isUpload,
            id_emr: this.id_emr,
          },
          {
            onUploadProgress: progressEvent => {
              const progress =
                Math.floor(progressEvent.loaded / progressEvent.total) * 50;
              this.documents[index].loading.value =
                this.documents[index].loading.value + progress;
              if (this.documents[index].loading.value == 100) {
                this.documents[index].loading.state = false;
              }
            },
          },
        )
        .then(response => {
          if (response)
            this.showFillSuccess(
              `Berhasil ${isUpload ? 'Mengunggah' : 'Menghapus'} File`,
            );
        });
    },
    async uploadFile(file, data) {
      this.documents[data.index].loading.state = true;
      const formData = new FormData();
      formData.append(`files`, file);
      formData.append(
        'identifier',
        !data.index ? this.id_emr : this.documents[data.index].id,
      );
      formData.append('tag', data.tag);
      formData.append('created_by', this.$store.getters.userLoggedIn.id);

      await axios
        .post(
          Constant.apiUrl.concat('/upload'),
          formData,
          {
            onUploadProgress: progressEvent => {
              // calculate for loading progress
              const progress =
                Math.floor(progressEvent.loaded / progressEvent.total) *
                  data.tag ===
                'informconsent'
                  ? 50
                  : 100;
              this.documents[data.index].loading.value =
                this.documents[data.index].loading.value + progress;
              if (this.documents[data.index].loading.value == 100) {
                this.documents[data.index].loading.state = false;
              }
            },
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(async () => {
          await this.postFile(true, data.index);
          this.showFillSuccess('Berhasil Mengunggah File');
          this.showAllDocuments();
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    async removeFile(file, data) {
      const payload = {
        tag: data.tag,
        identifier: !data.index ? this.id_emr : this.documents[data.index].id,
        file_id: [file.id],
      };
      await axios.delete(
        Constant.apiUrl.concat('/upload'),
        { data: payload },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (data.index) {
        this.showFillSuccess('Berhasil Menghapus File');
      }
    },
    deleteFile(data) {
      if (this.documents[data.index].document.length > 0) {
        Swal.fire({
          title: `Apakah Anda yakin ingin Menghapus Dokumen ${
            data.title
          } untuk Pasien ${this.documents[data.index].patient}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#2D9CDB',
          cancelButtonColor: '#EB5757',
          confirmButtonText: 'Hapus',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        })
          .then(async result => {
            if (result.isConfirmed) {
              await this.removeFile(
                this.documents[data.index].document[0],
                data,
              );
              await this.postFile(false, data.index);
            }
          })
          .catch(error => {
            this.showErrorAxios(error);
          })
          .finally(() => {
            this.showAllDocuments();
          });
      } else {
        this.documents[data.index].documentError = {
          file: '',
          message: '',
        };
      }
    },
    removeWarning(index) {
      this.documents[index].documentError = {
        file: '',
        message: '',
      };
    },
    generateTag(data) {
      let result;
      switch (data.toLowerCase()) {
        case 'general consent rawat jalan':
          result = 'generalconsent';
          break;
        case 'informed consent rawat jalan':
          result = 'informconsent';
          break;
        case 'surgical safety checklist':
          result = 'surgicalsafetychecklist';
          break;
        case 'penandaan operasi atau tindakan medis':
          result = 'medicaltreatment';
          break;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.document-card {
  border: 1px solid #137bc0;
  width: 100%;
  border-radius: 8px;
  padding: 1rem;
  min-height: 6rem;
  margin-bottom: 1rem;
  .item-container {
    min-height: 6rem;
  }
}
.search {
  border: 1px solid #9ca2a5;
  border-radius: 3px;
  width: 150px;
}

.btn-icon {
  border-color: #cdd2d7;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
}

.file-container {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  text-align: start;
  font-weight: 500;
  padding: 0.5rem;
  font-size: 0.9rem;
  background-color: #f5f5f5;
  min-width: 15rem;

  &.error {
    min-width: 20rem;
    background-color: #f5f5f5 !important;
  }
}
</style>
